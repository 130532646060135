<template>
  <minimal-bg-header-layout header-solo-logo>
    <div>
      <div class="container mx-auto p-6 content-center md:py-12 lg:px-12 md:w-1/2 xl:w-1/3">
        <div class="flex flex-col px-6 py-8 shadow-2xl rounded bg-white lg:px-12">
          <h1 class="text-2xl inline-flex self-center">
            Bienvenido a &nbsp; <strong>{{ businessFullName }}</strong>
          </h1>
          <p class="text-center mb-4">Completar mi perfil</p>
          <el-form
            :model="form"
            ref="inviteFormRef">
            <el-form-item prop="name" autocomplete="off">
              <strong>Nombre</strong>
              <el-input v-model="form.name" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="password" autocomplete="off">
              <strong>Contraseña</strong>
              <el-input :type="showPassword ? 'text' : 'password'" v-model="form.password" autocomplete="off">
                  <span
                    class="el-input__icon cursor-pointer text-sm hover:cursor-pointer flex items-center"
                    :class="showPassword ? 'text-red-400 hover:text-red-400': 'text-purple-500 hover:text-purple-400'"
                    slot="suffix"
                    @click="showPassword = !showPassword">
                     <i :class="showPassword ? 'text-xl bx bx-show text-red-500': 'text-xl bx bx-hide'"></i>
                  </span>
              </el-input>
            </el-form-item>
            <el-form-item prop="termsAccepted">
              <div class="flex flex-row items-start justify">
                <el-checkbox v-model="form.termsAccepted" @change="form.termsAccepted = form.termsAccepted === false ? null : true"/>
                <span class="py-1 ml-2 leading-loose">
              He leído y acepto los <router-link class="underline" to="/terms">términos y condiciones</router-link> y la <router-link class="underline" to="/privacy-policy">política de privacidad</router-link>.
            </span>
              </div>
            </el-form-item>
          </el-form>
          <sa-button
            type="primary"
            class="items-center font-bold rounded text-right"
            @click="register">
            <i class='bx bx-right-arrow-alt'></i> &nbsp; Completar mi Registro
          </sa-button>
        </div>
        <div class="p-2 w-full py-6 mt-12 border-t border-gray-200 text-center">
          <div class="inline-flex">
            <a
              class="mx-4 text-gray-500 hover:text-purple-500"
              href="#">
              Contacto
            </a>
          </div>
        </div>
      </div>
    </div>
  </minimal-bg-header-layout>
</template>
<script>
import MinimalBgHeaderLayout from '@/components/layouts/MinimalBgHeaderLayout.vue';
import SignService from '@/services/SignService';
import AdminService from '@/services/AdminService';
import error from '@/mixins/error';
import _ from "lodash";

export default {
  name: 'InviteIndex',
  components: {
    MinimalBgHeaderLayout,
  },
  mixins: [
    error,
  ],
  data() {
    return {
      showPassword: false,
      errorMessage: undefined,
      businessId: undefined,
      businessName: undefined,
      accountType: undefined,
      email: undefined,
      form: {
        name: undefined,
        password: undefined,
        termsAccepted: undefined,
        accountType: undefined,
      },
    }
  },
  mounted() {
    this.businessId = this.$route.params.businessId;
    this.businessName = this.$route.params.businessName;
    this.accountType = this.$route.params.accountType;
    this.email = this.$route.params.email;
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  computed: {
    businessFullName() {
      return this.businessName ? this.businessName.replaceAll('+', ' ') : '';
    },
  },
  methods: {
    async register() {
      if (!this.form.name || !this.form.password || !this.form.termsAccepted) {
        this.errorMessage = 'Por favor completa los campos';
        return;
      }
      try {
        const data = {
          businessId: this.businessId,
          businessName: this.businessName,
          accountType: this.accountType,
          email: this.email.toLowerCase(),
          password: this.form.password,
          membershipType: 'basic',
          fullname: this.form.name,
          termsAccepted: this.form.termsAccepted,
        };
        const result = await SignService.register(data);
        if (result) {
          await AdminService.MyBusiness.read();
          this.$router.replace({ name: 'home.index' });
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = this.getErrorMessage(e);
      }
    },
  },
}
</script>
